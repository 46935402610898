<template>
  <div class="container full-height">
    <div class="row email-row">
      <div class="col-12">
        <div class="card-static fit-content text-center">
          <p>Thank you for registering to BATTERS.</p>
          <p>
            Please click on the button below to validate your email address.
          </p>
          <button class="btn-filled mt-2" @click="validateEmail">
            Validate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
export default {
  name: 'EmailPage',
  methods: {
    async validateEmail() {
      try {
        const token = this.$route.params.token;
        const status = await Database.verifyEmail(token);

        if (status === 200) {
          this.$router.push({
            name: 'Success',
            params: { type: 'email-verification' },
          });
        }
      } catch (error) {
        this.$router.push({
          name: 'Error',
          params: { type: 'email-verification' },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.email-row {
  margin-top: $sp-3x;
}
</style>
